$(document).on 'turbolinks:load', ->
  setTimeout (=>
    $('#new_ticket_attention').fadeOut();
    return
  ), 2000

  $('#selectAll').click ->
    if @checked
      $('input[id^="otrs_emails_ids"]').each ->
        @checked = true
        return
    else
      $('input[id^="otrs_emails_ids"]').each ->
        @checked = false
        return
    return

  $('#pagination_counter').change ->
    url_data = getParams window.location.href
    page_counter = $(this).children("option:selected").val()
    url_data.limit = page_counter
    $.ajax
      url: "/otrs_emails"
      method: 'GET'
      dataType: 'json'
      data: url_data
      error: (xhr, status, error) ->
        console.error 'AJAX Error: ' + status + error
        return
      success: (response) ->
        $('#otrs_email_index_table').html(response.html)

  getParams = (url) ->
    params = {}
    parser = document.createElement('a')
    parser.href = url
    query = parser.search.substring(1)
    vars = query.split('&')
    i = 0
    while i < vars.length
      pair = vars[i].split('=')
      params[pair[0]] = decodeURIComponent(pair[1])
      i++
    params